<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-table :data="list">
        <el-table-column prop="goods_id" label="商品编号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="goods_title" label="商品名称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="ratio" label="盲返比例" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" width="300" show-overflow-tooltip>
          <template v-slot="s">
            <el-button @click="$router.push({
            path:'/shop/goods/edit',
            query:{id:s.row.goods_id}
            })">编辑商品</el-button>
            <el-button @click="remove(s.row)" type="danger">移出</el-button>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
  </div>
</template>

<script>

export default {
  components: {},
  name: "manage",
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    pageChange(e) {
      this.page = e;
      this.load();
    },
    remove(raw) {
      this.$u.api.shop.plugin.blindAward.goodsConfEdit({...raw, enable: false}).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
    load() {
      this.$u.api.shop.plugin.blindAward.goodsSearch({
        page: this.page,
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    },
  },
}
</script>

<style scoped>

</style>